import * as React from "react"
import "../styles/gallery.css"
import SocialMediaLinks from "./social/socialMedia";

const Community = () => {
    return (
        <section id="community" className="gallery-container">
            <div className="gallery-title is-size-6-desktop is-size-6-tablet is-size-6-mobile">JOIN THE<span
                className="gallery-title-red"><b> COMMUNITY</b></span></div>
            <div className="level-item">
                <SocialMediaLinks iconSize="fa-2x"/>
            </div>
        </section>
    )
}

export default Community;
