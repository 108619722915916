import React from 'react';
import Footer from './footer';
import NavBar from './navBar/navBar';
import '../styles/app.scss';
import {Helmet} from "react-helmet";

interface LayoutProps {
    children: React.ReactNode;
}

const Layout = (props: LayoutProps) => (
    <>
        <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1"/>
        </Helmet>
        <main>
            <NavBar/>
            <div>
                {props.children}
            </div>
            <Footer/>
        </main>
    </>
);

export default Layout;