import React from "react"
import "../../styles/navBar.css"
import {
    faFacebookF,
    faTwitter,
    faInstagram,
    faDiscord, faMediumM,
} from '@fortawesome/free-brands-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {graphql, useStaticQuery} from "gatsby";

export interface SocialMediaLinksProps {
    iconSize?: string;
}

const SocialMediaLinks = (props: SocialMediaLinksProps) => {
    const {site} = useStaticQuery(query);
    const {
        twitterHandle,
        discordServer,
        instagramPage,
        facebookPage,
        blogUrl,
    } = site.siteMetadata;

    return (
        <div className="level is-mobile">
            {twitterHandle && (
                <SocialMediaItem
                    icon={faTwitter}
                    iconSize={props.iconSize}
                    url={twitterHandle}
                    label="Follow us on Twitter"
                />
            )}
            {discordServer && (
                <SocialMediaItem
                    icon={faDiscord}
                    iconSize={props.iconSize}
                    url={discordServer}
                    label="Join our Discord Server"
                />
            )}
            {instagramPage && (
                <SocialMediaItem
                    icon={faInstagram}
                    iconSize={props.iconSize}
                    url={instagramPage}
                    label="Follow us on Instagram"
                />
            )}
            {facebookPage && (
                <SocialMediaItem
                    icon={faFacebookF}
                    iconSize={props.iconSize}
                    url={facebookPage}
                    label="Follow us on Facebook"
                />
            )}
            {blogUrl && (
                <SocialMediaItem
                    icon={faMediumM}
                    iconSize={props.iconSize}
                    url={blogUrl}
                    label="Follow us on Medium"
                />
            )}
        </div>
    )
}

export const SocialMediaItem = (props: any) => (
    <a
        className="level-item inherit-color p-2"
        rel="external noopener nofollow"
        href={props.url}
        target="_blank"
        aria-label={props.label}
        style={{color: props.color ? props.color : "#F55558"}}
    >
        <FontAwesomeIcon icon={props.icon} className={`${props.iconSize ? props.iconSize : "fa-xl"}`}/>
    </a>
);

export default SocialMediaLinks;

const query = graphql`
  query SocialMedia {
    site {
      siteMetadata {
        twitterHandle
        discordServer
        instagramPage
        facebookPage
        blogUrl
      }
    }
  }
`;
