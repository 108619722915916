import React, {useState} from "react"
import "../../styles/navBar.css"

import SocialMediaLinks from "../social/socialMedia"
import NavTiles from "./navTiles";
import {KeyDownEvent} from "../common/types";
import {selectKeyPressed} from "../common/utils";
import {Link} from "gatsby";
import MafiaverseLogo from "../common/MafiaverseLogo";


const NavBar = () => {
    const [isBurgerActive, toggleBurgerActive] = useState(false);

    const handleKeyDown = (keyDownEvent: KeyDownEvent) => {
        if (selectKeyPressed(keyDownEvent)) {
            toggleBurgerActive(!isBurgerActive);
        }
    };

    return (
        <nav className="navbar is-transparent is-black is-spaced"
             role="navigation"
             aria-label="main navigation">
            <div className="container">
                <div className="navbar-brand is-black">
                    <Link className="navbar-item" to="/">
                        <MafiaverseLogo/>
                    </Link>
                    <div
                        className={`navbar-burger burger ${
                            isBurgerActive ? 'is-active' : ''
                        }`}
                        tabIndex={0}
                        role="button"
                        onClick={() => toggleBurgerActive(!isBurgerActive)}
                        onKeyDown={handleKeyDown}
                        aria-label="menu"
                        aria-expanded={isBurgerActive}
                        aria-pressed={isBurgerActive}
                        data-target="menuContent"
                    >
                        <span aria-hidden="true"/>
                        <span aria-hidden="true"/>
                        <span aria-hidden="true"/>
                    </div>
                </div>

                <div id="navMenu"
                     className={`navbar-menu is-uppercase ${isBurgerActive ? 'is-active' : ''}`}>
                    <div className="navbar-start" style={{flexGrow: 1, justifyContent: "center"}}>
                        <NavTiles/>
                    </div>
                    <div className="navbar-end">
                        <SocialMediaLinks className="navbar-item is-hidden-touch"/>
                    </div>
                </div>
            </div>

        </nav>
    )
}

export default NavBar
