import React from "react";
import "../../styles/navBar.css";
import {AnchorLink} from "gatsby-plugin-anchor-links";
import {graphql, useStaticQuery} from "gatsby";

const NavTiles = (props: any) => {

    const {site} = useStaticQuery(query);
    const {
        mintPageUrl,
    } = site.siteMetadata;

    const navLinks = [
        {
            name: "MAFIAVERSE",
            path: "/"
        },
        {
            name: "MINT",
            path: mintPageUrl,
        },
        {
            name: "MONKS",
            path: "/#monks",
        },
        {
            name: "FAQ",
            path: "/#faq",
        },
    ];

    return (
        <>
            {navLinks.map((link, index) => {
                return (
                    <AnchorLink
                        to={link.path}
                        className="navbar-item nav-link"
                    >
                        {link.name}
                    </AnchorLink>
                );
            })}
        </>
    )
}

const query = graphql`
  query MintPageUrl {
    site {
      siteMetadata {
        mintPageUrl
      }
    }
  }
`;


export default NavTiles
