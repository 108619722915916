import {KeyDownEvent} from './types';
import {KeyCodes} from "../../constants/keyCodes";

export const selectKeyPressed = (keyDownEvent: KeyDownEvent): boolean => {
    let keyCode = keyDownEvent.keyCode || keyDownEvent.which;
    if (keyCode === KeyCodes.ENTER || keyCode === KeyCodes.SPACEBAR) {
        return true;
    }
    return false;
};
