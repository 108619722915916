import React from 'react';
import "../styles/footer.css"
import {Link} from "gatsby";
import MafiaverseLogo from "./common/MafiaverseLogo";
import Community from "./community";


const Footer = () => (
    <footer className="footer is-small">
        <div className="container">
            <div className="level">
                <div className="level-right">
                    <div className="level-item">
                        <Link to="/">
                            <MafiaverseLogo/>
                        </Link>
                    </div>
                </div>
                <div className="level-item">
                    <Community />
                </div>
                <div className="level-left">
                    <div className="level-item">
                        <Link className="mafiaverse-text navbar-item" to="/terms">TERMS</Link>
                        <Link className="mafiaverse-text navbar-item" to="/privacy">PRIVACY</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className="mafiaverse-text has-text-centered">
            {' '}
            © {new Date().getFullYear()} Mafiaverse.
        </div>
    </footer>
);

export default Footer;
